import fav from "../assets/images/ico/favicon.png";
export const debugMode = false;
export const dummyMode = false;
export const prod = false;
export const apiKey = debugMode
  ? "Api-Key NYoMfNCW.9Y6n97FFpjO9GGDJjwYWDtwNCXUdXme6"
  : window.apikey;
export const access = localStorage.getItem("accessToken");
export const refreshAccess = localStorage.getItem("refreshToken");
export const isDomain = () => localStorage.getItem("customer_domain");
export const customDomain = isDomain() ? JSON.parse(isDomain()) : "";

export const baseUrl = debugMode
  ? "http://192.168.100.8:8000"
  : window.location.origin;
export const noAuthHeaders = {
  headers: {
    "content-type": "application/json",
    Authorization: apiKey,
    openApi: true,
  },
};
export const AuthHeaders = {
  headers: {
    "content-type": `application/json`,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    Authorization: `Bearer ${access}`,
  },
};
export const companyDataJSON = () => localStorage.getItem("company");
export const companyData = companyDataJSON()
  ? JSON.parse(companyDataJSON())
  : "";
export const userRetailor = companyData.member_of === "dealer" ? true : false;
export const userRf =
  companyData.member_of === "repair_facility" ? true : false;
export const userAgent = companyData.member_of === "agency" ? true : false;
export const userSr =
  companyData.member_of === "sales_representative" ? true : false;

const ownerShip = () => {
  return !userRetailor && !userRf && !userAgent ? true : false;
};

export const cOf = companyData.is_owner;
export const automation = companyData.automated_approval;
export const userOwner = ownerShip();
export const claimId = companyData.claim_id;
export const memberId = companyData.member_id;
export const companyBase = "/company";
export const dealerBase = "/dealer";
export const companyDetail = companyData;
export const companyId = memberId;
export const dealerId = companyData.dealer_id;
export const rfId = companyData.repair_facility_id;
export const saleId = companyData.sales_representative_id;

export const agentId = companyData.agent_id;
export const agencyId = companyData.agency_id;
export const srId = memberId;
const userPerm = localStorage.getItem("userperm");
export const permission = userPerm ? JSON.parse(userPerm) : [];
export const persmissionParse = () => {
  return permission && JSON.parse(permission);
};
export const userId = companyData.user_id;
export const mOf = companyData.member_of;
const verifyUrl = `${baseUrl}/api/account/token/verify/`;
const refreshUrl = `${baseUrl}/api/account/token/refresh/`;
import axios from "axios";
import { Info } from "react-feather";
import { toast } from "react-toastify";
import { ToastComponent } from "../custom-utils/custom-reusable/ToastComponent";
export const screenSize = screen.width;

export const fversion = "4.5.22";

export const tokenVerify = () => {
  if (access) {
    const data = {
      token: access,
    };
    axios
      .post(verifyUrl, data, AuthHeaders)
      .then((res) => {
        "";
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          const refreshData = {
            refresh: refreshAccess,
          };
          axios
            .post(refreshUrl, refreshData, AuthHeaders)
            .then((res) => {
              localStorage.setItem("accessToken", res.data.access);
              window.location.reload();
            })
            .catch((err) => {
              if (err.response.status === 401) {
                localStorage.removeItem("accessToken");
                localStorage.removeItem("is_retailer");
                localStorage.removeItem("refreshToken");
                localStorage.removeItem("company");
                localStorage.removeItem("userperm");
                setTimeout(() => {
                  if (window.location.pathname !== "/login") {
                    window.location.assign("/app/login");
                  }
                }, 1000);
              }
            });
        } else {
          toast.error(
            <ToastComponent
              title="Network error"
              color="danger"
              icon={<Info />}
            />,
            {
              autoClose: 2000,
              hideProgressBar: true,
              closeButton: false,
            }
          );
        }
      });
  }
};
export const tokenVerify2 = () => {
  const data = {
    token: access,
  };
  axios
    .post(verifyUrl, data, AuthHeaders)
    .then((res) => {
      "";
    })
    .catch((err) => {
      if (err.response && err.response.status === 401) {
        toast.error(
          <ToastComponent
            title="Session expired"
            color="danger"
            icon={<Info />}
          />,
          {
            autoClose: 2000,
            hideProgressBar: true,
            closeButton: false,
          }
        );

        localStorage.removeItem("accessToken");
        localStorage.removeItem("is_retailer");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("company");
        localStorage.removeItem("userperm");
        setTimeout(() => {
          if (window.location.pathname !== "/login") {
            window.location.assign("/app/login");
          }
        }, 1000);
      } else {
        toast.error(
          <ToastComponent
            title="Network error"
            color="danger"
            icon={<Info />}
          />,
          {
            autoClose: 2000,
            hideProgressBar: true,
            closeButton: false,
          }
        );
      }
    });
};

export const getPermission = () => {
  axios
    .post(
      `${baseUrl}/api/account/${companyId}/user/permission/`,

      noAuthHeaders
    )
    .then((res) => {
      if (permission !== JSON.stringify(res.data.permission)) {
        localStorage.setItem("userperm", JSON.stringify(res.data.permission));
      }
    });
};

export const logingOut = () => {
  localStorage.removeItem("company");
  localStorage.removeItem("userperm");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("accessToken");
  window.location.assign("/app/login");
};
export const defaultColorApp = "#0293de";
export const primaryColor = customDomain.color
  ? customDomain.color
  : defaultColorApp;
export const linkColor = customDomain.link_color
  ? customDomain.link_color
  : defaultColorApp;

export const userColor = primaryColor;
export const userFavicon = customDomain.favicon ? customDomain.favicon : fav;
export const userName = customDomain.site_title
  ? customDomain.site_title
  : "DealerAdmin.io";

export const customNavStyle = {
  background: userColor,
};

export const tabStyleRender = (num, activeTab) => {
  return {
    background: activeTab === num && userColor,
    boxShadow: activeTab === num && boxShadow,
    borderColor: activeTab === num && userColor,
  };
};

export const convertHexToRGBA = (hexCode, opacity) => {
  let hex = hexCode && hexCode.replace("#", "");
  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${opacity})`;
};

export const bgColor = {
  background: userColor,
};
export const textColor = {
  color: userColor,
};
export const linearBg = `linear-gradient(135deg,${convertHexToRGBA(
  userColor,
  1
)},${convertHexToRGBA(userColor, 0.7)}`;
export const boxShadow = `0 4px 8px ${userColor}`;
export const defaultName = `DealerAdmin.io`;
export const STRIPE_PUBLIC_KEY = prod
  ? process.env.REACT_APP_STRIPE_KEY_PROD
  : process.env.REACT_APP_STRIPE_KEY;
